<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <InvoiceDialog
      :show="showDialog"
      :item="dialogItem"
      :items="undispatch_items"
      v-on:onClose="onCloseEvent"
      @setInvoiceCustomer="setInvoiceCustomer"
      @rollbackExtraInvoice="rollbackExtraInvoice"
    ></InvoiceDialog>
    <InvoiceDetailDialog
      :show="showDialog2"
      :item="editObj"
      :invoice="editInvoice"
      :allowNext="allowNext"
      :invoiceDate="invoiceDate"
      v-on:onSave="saveAndNext"
      v-on:onClose="onCloseEvent2"
      v-on:next="next"
      v-on:disconnect="disconnect"
      v-on:triggerPatchApi="patchApi"
      v-on:updateUnitPrice="updateUnitPrice"
    ></InvoiceDetailDialog>
    <InvoiceCustomerDialog
      :show="showDialog3"
      :item="dialogItem3"
      v-on:onClose="onCloseEvent3"
      v-on:onSave="onSaveEvent3"
    ></InvoiceCustomerDialog>
    <v-row>
      <v-col class="text-left">
        <v-btn
          icon
          class="hidden-xs-only"
          @click="
            $router.push({
              name: 'InvoiceList',
              params: {}
            })"
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-col>

      <v-col class="text-right">
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-2"
          @click="exportBalanceSheet"
        >
          <v-icon>mdi-file-excel</v-icon>
          {{ $t("export")+$t("balance_list") }}
        </v-btn>
      </v-col>
      <v-col class="text-right" style="flex-grow: 0">
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-2"
          @click="exportDetailList"
        >
          <v-icon>mdi-file-excel</v-icon>
          {{ $t("export")+$t("detail_list") }}
        </v-btn>
      </v-col>
      <v-col class="text-right" style="flex-grow: 0">
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-2"
          @click="exportCargoList"
        >
          <v-icon>mdi-file-excel</v-icon>
          {{ $t("export")+$t("cargo_handling_list") }}
        </v-btn>
      </v-col>
      <v-col class="text-right" style="flex-grow: 0">
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-2"
          @click="exportCargoSplitList"
        >
          <v-icon>mdi-file-excel</v-icon>
          {{ $t("export")+$t("cargo_handling_split_list") }}
        </v-btn>
      </v-col>
      <v-col class="text-right" style="flex-grow: 0">
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-2"
          @click="invoiceEditLoop"
        >
          <v-icon>mdi-file-excel</v-icon>
          {{ $t("invoice.edit") }}
        </v-btn>
      </v-col>
    </v-row>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="invoiceDate + $t('invoice.list') + (noMultiInvoiceCustomer?'     無可多開發票客戶':'')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        :single-expand="true"
        multi-sort
        item-key="customer_id"
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.tally_payment="{ item }">
          {{ item.tally_payment + item.extra_tally_payment }}
        </template>
        <template v-slot:item.advance_payment="{ item }">
          {{ item.advance_payment + item.extra_advance_payment }}
        </template>
        <template v-slot:item.custom_payment="{ item }">
          {{ item.custom_payment + item.extra_custom_payment }}
        </template>
        <template v-slot:item.customer="{ item }">
          {{ get_customer_name(item) }}
          <v-tooltip bottom >
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                v-if="hasCashOnDeliverItem(item)"
              >mdi-alert-circle</v-icon>
            </template>
            <span>{{ $t("invoice.cash_on_deliver_hint") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.need_invoice="{ item }">
          <v-edit-dialog
            @save="updateField(item.id,'need_invoice',item.need_invoice)"
            :save-text="$t('save')"
            :cancel-text="$t('cancel')"
            large
          >
            <div :style="'color: '+needInvoiceColor(item)"
            >{{ item.need_invoice | getText(invoice_items) | Translate }}<v-icon>mdi-pencil</v-icon></div>
            <template v-slot:input>
              <v-select
                v-model="item.need_invoice"
                :items="invoice_items"
                :item-text="item => $t(item.text)"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="invoiceEdit(item)"
              >mdi-pencil</v-icon>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="invoiceCustomerEdit(item)"
              >mdi-account-edit</v-icon>
            </template>
            <span>{{ $t("invoice.change_customer") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                :disabled="item.need_invoice!==2"
                :color="item.need_invoice?'green':''"
                @click="appendItem(item)"
              >mdi-plus</v-icon>
            </template>
            <span>{{ $t("invoice.dispatch") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                :disabled="item.status==2"
                @click="updateField(item.id,'status',2)"
              >mdi-check</v-icon>
            </template>
            <span>{{ $t("invoice.issued") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import InvoiceDialog from "../dialogs/InvoiceDialog";
import InvoiceDetailDialog from "../dialogs/InvoiceDetailDialog";
import InvoiceCustomerDialog from "../dialogs/InvoiceCustomerDialog"
import { invoice_items,invoice_need } from "@/definition.js";
export default {
  data: (vm) => ({
    url: "/container/invoice/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("invoice.customer"),
        value: "customer",
        // width: "5%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("invoice.need_invoice"),
        value: "need_invoice",
        // width: "5%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("invoice.tally_payment"),
        value: "tally_payment",
      },
      {
        sortable: true,
        text: vm.$i18n.t("invoice.advance_payment"),
        value: "advance_payment",
      },
      {
        sortable: true,
        text: vm.$i18n.t("invoice.custom_payment"),
        value: "custom_payment",
      },
      {
        sortable: true,
        text: vm.$i18n.t("invoice.amount"),
        value: "subtotal",
        // width: "5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "14%",
      },
    ],
    items: [],
    invoice_items: invoice_items,
    invoice_need: invoice_need,
    undispatch_items: [],
    expanded: [],
    invoiceDate: "",
    showDialog2: false,
    editIndex: 0,
    editObj: {},
    editInvoice: {},
    defaultCustomInvoice: "",
    defaultAdvanceInvoice: "",
    defaultTallyInvoice: "",
    allowNext: false,
    invoice_group: null,

    showDialog3: false,
    dialogItem3: {},

    noMultiInvoiceCustomer: false,
  }),
  mixins: [BaseTable],
  components: {
    InvoiceDialog,
    CommonAlertDialog,
    InvoiceDetailDialog,
    InvoiceCustomerDialog,
  },
  computed: {},
  methods: {
    exportDetailList() {
      const url = "/container/container/getDetailList/";
      if (this.invoice_group) {
        this.postFileApi(
          { invoice_group: this.invoice_group },
          url,
          "發票明細_" + this.invoiceDate
        );
      } else {
        alert(this.$i18n.t("alert.selected-box-empty"));
      }
    },
    exportBalanceSheet() {
      console.log("exportBalanceSheet", this.invoice_group);
      // 匯出對帳單
      const url = "/container/container/getBalanceSheets/";
      this.postFileApi(
        { invoice_group: this.invoice_group },
        url,
        "對帳單_" + this.invoiceDate
      );
    },
    setResult(data) {
      console.log('setResult',data)
      this.totalItems = data.count;
      this.items = data.results;
    },
    setInvoiceCustomer(data) {
      //call api
      const url = "/container/invoice_group/dispatchInvoice/";
      data['invoice_group'] = this.invoice_group
      this.postApi(data, url,this.afterSetInvoice);
    },
    
    afterSetInvoice(action, data){
      this.getApi()
      this.showDialog = false
      this.appendItem(data)
      // this.showDialog = false
    },
    rollbackExtraInvoice(data) {
      const url = "/container/invoice_group/rollbackDispatchInvoice/";
      this.postApi(data, url,this.afterSetInvoice);
    },
    disconnect(box_id) {
      const url = "/container/invoice_group/disconnectInvoice/";
      this.postApi({ container_box: box_id }, url,this.afterDisconnect);
    },
    afterDisconnect(action, data){
      this.getApi()
      this.invoiceEdit(data)
    },
    // updateCustomerInvoiceSetting(customer_id, val) {
    //   const url = "/customer/customer/";
    //   var jsonData = {};
    //   jsonData["need_invoice"] = val;
    //   this.patchApi(customer_id, jsonData, url);
    // },
    invoiceEdit(item) {
      console.log('invoiceEdit',item.id)
      this.get_invoice_detail(item.id);
      this.allowNext = false
      this.action = this.action_items.edit;
      this.showDialog2 = true;
    },
    invoiceEditLoop() {
      this.editIndex = 0;
      // console.log('invoiceEditLoop',(this.editIndex < (this.items.length - 1)))
      this.allowNext = (this.editIndex < (this.items.length - 1))
      this.get_invoice_detail(this.items[this.editIndex].id);
      this.action = this.action_items.edit;
      this.showDialog2 = true;
    },
    saveAndNext(item,goNext) {
      this.onSaveEvent(item);
      if (item.custom_invoice && item.advance_invoice && item.tally_invoice) {
        this.defaultCustomInvoice = this.getNextInvoice(item.custom_invoice);
        this.defaultAdvanceInvoice = this.getNextInvoice(item.advance_invoice);
        this.defaultTallyInvoice = this.getNextInvoice(item.tally_invoice);
      }
      if (goNext) {
        this.next();
      } else {
        this.showDialog2 = false;  
      }
    },
    getNextInvoice(invoice) {
      var re = /^(\w{2})(\d{8})$/;
      let regex = invoice.match(re);
      // console.log(regex[0], regex[1], regex[2]);
      return regex[1] + String(parseInt(regex[2]) + 1);
    },
    next() {
      this.editIndex += 1;
      // console.log('next',(this.editIndex < (this.items.length - 1)))
      this.allowNext = (this.editIndex < (this.items.length - 1))
      this.get_invoice_detail(this.items[this.editIndex].id);
      this.action = this.action_items.edit;
    },
    onCloseEvent2() {
      this.showDialog2 = false;
    },
    get_customer_name(item) {
      return this.getHanText(item.customer, "name");
    },
    get_invoice_amount(item) {
      return item.advance_payment + item.custom_payment + item.tally_payment;
    },
    get_invoice_detail(id) {
      this.getApi(
        "/container/invoice/" + id + "/getInvoiceDetail/",
        this.setDetail,
        true
      );
    },
    setDetail(data) {
      if (
        !data.invoice.custom_invoice &&
        !data.invoice.advance_invoice &&
        !data.invoice.tally_invoice
      ) {
        if (
          this.defaultCustomInvoice &&
          this.defaultAdvanceInvoice &&
          this.defaultTallyInvoice
        ) {
          data.invoice.custom_invoice = this.defaultCustomInvoice;
          data.invoice.advance_invoice = this.defaultAdvanceInvoice;
          data.invoice.tally_invoice = this.defaultTallyInvoice;
        }
      }

      this.editObj = data;
      this.editInvoice = data.invoice
    },
    updateInvoice(id, field, val) {
      this.updateField(id, field, val);
    },
    appendItem (item) {
      // console.log('this.invoice_group',this.invoice_group,item)
      // get undispatch datas
      this.getApi(
        "/container/invoice_group/" + item.invoice_group + "/getUndispatchData/",
        this.setUndispatch,
        true
        )
      this.dialogItem = item
      this.showDialog = true
    },
    setUndispatch (data) {
      // console.log('setUndispatch',data)
      this.undispatch_items = data.result
    },
    invoiceCustomerEdit (item) {
      this.dialogItem3 = this._.cloneDeep(item);
      this.showDialog3 = true
    },
    onCloseEvent3(){
      this.showDialog3 = false
    },
    onSaveEvent3 (invoice, customer) {
      this.showDialog3 = false
      this.updateField(invoice, "customer", customer)
    },
    exportCargoList(){
      // 匯出理貨單
      const url = "/container/invoice_group/"+this.invoice_group+"/getCargoList/";
      // this.getApi(url,this.exportCargoSplitList);
      this.getFileApi(url,"理貨表");
    },
    exportCargoSplitList(){
      const url = "/container/invoice_group/"+this.invoice_group+"/getCargoSplitList/";
      this.getFileApi(url,"理貨表-拆");
    },
    hasCashOnDeliverItem(item){
      const extra = JSON.parse(item.extra)
      return extra ? extra['has_cash_on_deliver_item'] : ""
    },
    needInvoiceColor(item){
      if (item.need_invoice==0) {
        return "red"
      } else if (item.need_invoice==2){
        return "green"
      } else if (item.need_invoice==3){
        return "gray"
      }
      return ""
    },
    updateUnitPrice(container_id,customer_id,unit_price, unit) {
      console.log("updateUnitPrice",container_id,customer_id,unit_price, unit)
      const url = "/container/container_box/multipleUpdateUnitPrice/";
      this.postApi({ container_id: container_id, customer_id: customer_id, unit_price: unit_price,unit:unit }, url,this.afterUpdateUnitPrice);
    },
    afterUpdateUnitPrice(action, data){
      console.log(data)
      this.get_invoice_detail(data['invoice_id']);
    },
  },
  watch: {
    items(val){
      const more_invoice = val.filter(({need_invoice})=>need_invoice==2);
      // console.log('more_invoice',more_invoice.length)
      const no_invoice = val.filter(({need_invoice})=>need_invoice==0);
      // console.log('no_invoice',no_invoice.length)
      this.noMultiInvoiceCustomer = no_invoice.length > 0 && more_invoice.length==0
    },
  },
  mounted() {
    this.invoice_group = this.$route.query.invoice_group;
    this.invoiceDate = this.$route.query.name;
    this.getParams = {
      invoice_group: this.invoice_group,
    };
    console.log('this.invoice_group',this.invoice_group)
  },
};
</script>
