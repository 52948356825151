<template>
  <v-dialog
    v-model="show"
    :max-width="'80%'"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}-{{ customerName }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-edit-dialog
                @save="updateCustomerField(item.invoice.customer.id, 'company_name', item.invoice.customer.company_name)"
                :save-text="$t('save')"
                :cancel-text="$t('cancel')"
                large
              >
                <h2>抬頭&nbsp;{{ companyName }}<v-icon>mdi-pencil</v-icon></h2>
                
                <template v-slot:input>
                  <v-text-field v-model="item.invoice.customer.company_name"></v-text-field>
                </template>
              </v-edit-dialog>
            </v-col>
            <v-col style="text-align: center;">
              <h2>{{ invoiceDate }}</h2>
            </v-col>
            <v-col style="text-align: right;">
              <v-edit-dialog
                @save="updateCustomerField(item.invoice.customer.id, 'gui_number', item.invoice.customer.gui_number)"
                :save-text="$t('save')"
                :cancel-text="$t('cancel')"
                large
              >
                <h2>統一編號&nbsp;{{ guiNumber }}<v-icon>mdi-pencil</v-icon></h2>
                
                <template v-slot:input>
                  <v-text-field v-model="item.invoice.customer.gui_number"></v-text-field>
                </template>
              </v-edit-dialog>
            </v-col>
          </v-row>
          <v-row style="display: block">
            <v-simple-table>
              <thead>
                <tr>
                  <th>類別</th>
                  <th>櫃號</th>
                  <th>裝櫃日</th>
                  <th>箱數</th>
                  <th>品名</th>
                  <th>環保稅</th>
                  <th>重量</th>
                  <th>才積</th>
                  <th>運價</th>
                  <th>應收</th>
                  <th style="border-left: thin solid black;"></th>
                  <!-- <th>墊付款</th>
                  <th>理貨費</th> -->
                  <th>才積費</th>
                  <!-- <th></th> -->
                </tr>
              </thead>
              <tbody>
                <template v-for="data,index in item.detail">
                  <tr>
                    <template v-if="data.rowspan>0">
                      <td :rowspan="data.rowspan">運費</td>
                      <td :rowspan="data.rowspan">{{ data.container_number }}</td>
                      <td :rowspan="data.rowspan">{{ data.stuffing_date }}</td>
                      <td :rowspan="data.rowspan">{{ data.total_box_amount }}</td>
                    </template>
                    <td>{{ data.title }}</td><!-- 品名 -->
                    <td>{{ data.eco_tax }}</td><!-- 環保稅 -->
                    <td>{{ data.weight }}</td><!-- 重量 -->
                    <td>{{ data.cubic_feet }}</td><!-- 才積 -->
                    <td>
                      <!-- {{ data.unit_price }} -->
                      <v-edit-dialog
                        @save="updateContainerUnitPrice(data.container_id,item.invoice.customer.id,data.unit_price,data.unit)"
                        :save-text="$t('save')"
                        :cancel-text="$t('cancel')"
                        large
                      >
                        <div>{{ data.unit_price }}<v-icon>mdi-pencil</v-icon></div>
                        <template v-slot:input>
                          <v-text-field v-model="data.unit_price"></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </td><!-- 運價 -->
                    <td>{{ data.total_price }}</td><!-- 應收 -->
                    <template v-if="index==0">
                      <td :rowspan="item.detail.length" style="border: thin solid black;text-align: right;">
                        應收總和
                        <br>
                        {{ item.total }}</td><!-- 應收總和 -->  
                    </template>
                    
                    <template v-if="data.rowspan>0">
                      
                      <!-- 墊付款 -->
                      <!-- <td :rowspan="data.rowspan" style="text-align: right;">{{ data.advance_payment }}</td> -->
                      <!-- 理貨費 -->
                      <!-- <td :rowspan="data.rowspan" style="text-align: right;">{{ data.tally_payment }}</td> -->
                      <td :rowspan="data.rowspan" style="text-align: right;">{{ data.total_cubic_feet_fee }}</td><!-- 才積費 -->
                    </template>
                    
                    <!-- <td>移除
                      <template v-if="data.customer != item.invoice.customer.id">
                        <v-icon @click="disconnect(data.id)">mdi-delete</v-icon>
                      </template>
                    </td> -->
                  </tr>
                </template>
                <!-- 自己的發票稅 -->
                <template v-if="invoice.need_invoice !== 0">
                  <tr>
                    <td>發票稅</td>
                    <td>
                      <v-text-field
                        label="理貨費發票號碼"
                        v-model="invoice.tally_invoice"
                        variant="outlined"
                      ></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ invoice.tally_payment }}</td>
                    <td>5%</td>
                    <td>{{ fixedFloat(invoice.tally_payment * 0.05, 0) }}</td>
                    <td :rowspan="2" style="border-bottom: thin solid black;border-left: thin solid black;text-align: right;">
                      理貨+墊付
                      <br>
                      {{ invoice.tally_payment+invoice.advance_payment }}</td>
                    <!-- <td></td> -->
                    <!-- <td></td> -->
                    <td :rowspan="2" style="border: thin solid black;text-align: right;">
                      才積費總和
                      <br>
                      {{ item.total_cff }}</td>
                  </tr>
                  <tr>
                    <td>發票稅</td>
                    <td>
                      <v-text-field
                        label="墊付款項發票號碼"
                        v-model="invoice.advance_invoice"
                        variant="outlined"
                      ></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ invoice.advance_payment }}</td>
                    <td>5%</td>
                    <td>{{ fixedFloat(invoice.advance_payment * 0.05, 0) }}</td>
                    <!-- <td style="border-left: thin solid black;"></td> -->
                    <!-- <td></td> -->
                    <!-- <td></td> -->
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td>發票稅</td>
                    <td>
                      <v-text-field
                        label="報關金額發票號碼"
                        v-model="invoice.custom_invoice"
                        variant="outlined"
                      ></v-text-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ invoice.custom_payment }}</td>
                    <td>5%</td>
                    <td>{{ fixedFloat(invoice.custom_payment * 0.05, 0) }}</td>
                    <td style="border-left: thin solid black;"></td>
                    <!-- <td></td> -->
                    <!-- <td></td> -->
                    <td></td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td :rowspan="2" style="border-bottom: thin solid black;border-left: thin solid black;text-align: right;"></td>
                    <!-- <td></td> -->
                    <!-- <td></td> -->
                    <td :rowspan="2" style="border: thin solid black;text-align: right;">
                      才積費總和
                      <br>
                      {{ item.total_cff }}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <!-- <td style="border-left: thin solid black;"></td> -->
                    <!-- <td></td> -->
                    <!-- <td></td> -->
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="border-left: thin solid black;"></td>
                    <!-- <td></td> -->
                    <!-- <td></td> -->
                    <td></td>
                  </tr>
                </template>
                  <!-- 追加的發票稅 -->
                  <template v-if="invoice.has_extra_invoice">
                    <tr>
                      <td>發票稅</td>
                      <td>
                        <v-text-field
                          label="理貨費發票號碼"
                          v-model="invoice.extra_tally_invoice"
                          variant="outlined"
                        ></v-text-field>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ invoice.extra_tally_payment }}</td>
                      <td>8%</td>
                      <td>{{ fixedFloat(invoice.extra_tally_payment * 0.08, 0) }}</td>
                      <td :rowspan="2" style="border-top: thin solid black;border-bottom: thin solid black;border-left: thin solid black;text-align: right;">
                        理貨+墊付
                        <br>
                        {{ invoice.extra_tally_payment+invoice.extra_advance_payment }}</td>
                      <!-- <td></td> -->
                      <!-- <td></td> -->
                      <td :rowspan="2" style="border: thin solid black;text-align: right;">
                        才積費總和
                        <br>
                        <!-- 須額外取得材積費總額 -->
                        0
                        <!-- {{ item.extra_total_cff }} -->
                      </td>
                    </tr>
                    <tr>
                      <td>發票稅</td>
                      <td>
                        <v-text-field
                          label="墊付款項發票號碼"
                          v-model="invoice.extra_advance_invoice"
                          variant="outlined"
                        ></v-text-field>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ invoice.extra_advance_payment }}</td>
                      <td>8%</td>
                      <td>{{ fixedFloat(invoice.extra_advance_payment * 0.08, 0) }}</td>
                      <!-- <td style="border-left: thin solid black;"></td> -->
                      <!-- <td></td> -->
                      <!-- <td></td> -->
                      <!-- <td></td> -->
                    </tr>
                    <tr>
                      <td>發票稅</td>
                      <td>
                        <v-text-field
                          label="報關金額發票號碼"
                          v-model="invoice.extra_custom_invoice"
                          variant="outlined"
                        ></v-text-field>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ invoice.extra_custom_payment }}</td>
                      <td>8%</td>
                      <td>{{ fixedFloat(invoice.extra_custom_payment * 0.08, 0) }}</td>
                      <td style="border-left: thin solid black;"></td>
                      <!-- <td></td> -->
                      <!-- <td></td> -->
                      <td></td>
                  </tr>
                </template>
                <!-- 小計 -->
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>小計：</td>
                  <td>{{ invoice.subtotal }}</td>
                  <td style="border-left: thin solid black;"></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!allowNext"
          @click="next"
        >{{ $t("next") }}</v-btn>
        <template v-if="allowNext===true">
          <v-btn
            color="primary"
            :disabled="allowSave"
            @click="saveAndNext"
          >{{ $t("save and next") }}</v-btn>
        </template>
        <template v-else>
          <v-btn
            color="primary"
            :disabled="allowSave"
            @click="saveOnly"
          >{{ $t("save") }}</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  props: {
    allowNext: false,
    invoiceDate: '',
    invoice: {},
  },
  data: (vm) => ({
    topic: vm.$i18n.t("invoice.detail"),
  }),
  mixins: [BaseDialog],
  computed: {
    allowSave() {
      return false;
    },
    customerName() {
      if (this.invoice) {
        if (this.invoice.customer) {
          return this.invoice.customer.name_tc
        }
      }
      return ''
    },
    companyName(){
      if (this.invoice) {
        if (this.invoice.customer) {
          return this.invoice.customer.company_name
        }
      }
      return ''
    },
    guiNumber() {
      if (this.invoice) {
        if (this.invoice.customer) {
          return this.invoice.customer.gui_number
        }
      }
      return ''
    },
  },
  methods: {
    next() {
      this.$emit("next");
    },
    saveAndNext(){
      this.save(true)
    },
    saveOnly() {
      this.save(false)
    },
    save(goNext) {
      let editedItem = this._.cloneDeep(this.invoice);
      this.$emit("onSave", editedItem, goNext);
    },
    disconnect(box_id) {
      this.$emit("disconnect",box_id)
    },
    calculateReceivable(cubic_feet, custom_deliver_fee){
      console.log('calculateReceivable',cubic_feet * custom_deliver_fee, 170)
      return Math.max(cubic_feet * custom_deliver_fee, 170)
    },
    showTip(cubic_feet, custom_deliver_fee){
      return cubic_feet * custom_deliver_fee < 170
    },
    updateCustomerField(id, field, value) {
      console.log(field + ": " + value);
      var jsonData = {};
      jsonData[field] = value;
      this.$emit('triggerPatchApi',id,jsonData,"/customer/customer/")
    },
    updateContainerUnitPrice(container_id,customer_id, unit_price, unit) {
      console.log("updateContainerUnitPrice",container_id,customer_id,unit_price, unit)
      this.$emit('updateUnitPrice',container_id,customer_id, unit_price, unit)
    },
  },
};
</script>
