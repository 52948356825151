<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ item.customer_name }}{{ formTitle }}</span>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="rollbackExtraInvoice"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("invoice.rollback") }}
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="[target_item,selected_item,summary_item]"
            :hide-default-footer="true"
          >
            <!-- <template v-slot:item.customer="{ item }">
              {{ get_customer_name(item) }}
            </template> -->
            <!-- <template v-slot:item.tally_payment="{ item }">
              {{ item.tally_payment + arraySum(selected, "tally_payment") }}
            </template>
            <template v-slot:item.advance_payment="{ item }">
              {{ item.advance_payment + arraySum(selected, "advance_payment") }}
            </template>
            <template v-slot:item.custom_payment="{ item }">
              {{ item.custom_payment + arraySum(selected, "custom_payment") }}
            </template>
            <template v-slot:item.total="{ item }">
              {{ item.subtotal + arraySum(selected, "total") }}
            </template> -->
          </v-data-table>
          <v-row dense>
            <v-col class="pl-4">{{ $t("container.selected") + ': ' + selected.length }}</v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            show-select
            item-key="customer_id"
            fixed-header
            :height="390"
            :options.sync="options"
            :footer-props="footerProps"
            :single-select="false"
          >
            <!-- <template v-slot:item.tally_payment="{ item }">
              {{ fixedFloat(item.tally_payment, 2 , 0) }}
            </template> -->
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="setInvoiceCustomer"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("invoice.set") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  props: {
    items: [],
  },
  data: (vm) => ({
    topic: vm.$i18n.t("invoice.dispatch"),
    // 欄位：客戶、理貨費、墊付款、報關金額、總和
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("invoice.customer"),
        value: "customer",
      },  
      {
        sortable: true,
        text: vm.$i18n.t("invoice.tally_payment"),
        value: "tally_payment",
      },
      {
        sortable: true,
        text: vm.$i18n.t("invoice.advance_payment"),
        value: "advance_payment",
      },
      {
        sortable: true,
        text: vm.$i18n.t("invoice.custom_payment"),
        value: "custom_payment",
      },
      {
        sortable: true,
        text: vm.$i18n.t("invoice.total_price"),
        value: "total",
      },
    ],
    selected: [],
    selected_amount: 0,
    options: {},
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [20, 50, 100]
    },
  }),
  components: {},
  mixins: [BaseDialog],
  computed: {
    total_amount() {
      return this.item.amount + this.selected_amount;
    },
    target_item() {
      let customer = ""
      if (this.item&&this.item.customer) {
        return {
          'customer': this.item.customer.name_tc,
          'tally_payment': this.item.tally_payment + this.item.extra_tally_payment,
          'advance_payment': this.item.advance_payment + this.item.extra_advance_payment,
          'custom_payment': this.item.custom_payment + this.item.extra_custom_payment,
          'total': this.item.subtotal,
        }
      }
      return {
        'customer': "",
        'tally_payment': 0,
        'advance_payment': 0,
        'custom_payment': 0,
        'total': 0,
      }
    },
    selected_item() {
      return {
        'customer': this.$i18n.t("container.selected"),
        'tally_payment': this.arraySum(this.selected, "tally_payment"),
        'advance_payment': this.arraySum(this.selected, "advance_payment"),
        'custom_payment': this.arraySum(this.selected, "custom_payment"),
        'total': this.arraySum(this.selected, "total"),
      } 
    },
    summary_item() {
      return {
        'customer': this.$i18n.t("container.summary"),
        'tally_payment': this.item.tally_payment+this.item.extra_tally_payment+this.arraySum(this.selected, "tally_payment"),
        'advance_payment': this.item.advance_payment+this.item.extra_advance_payment+this.arraySum(this.selected, "advance_payment"),
        'custom_payment': this.item.custom_payment+this.item.extra_custom_payment+this.arraySum(this.selected, "custom_payment"),
        'total': this.item.subtotal+this.arraySum(this.selected, "total"),
      }
    },
  },
  methods: {
    setInvoiceCustomer() {
      var ids = [];
      if (this.selected.length > 0) {
        for (const [key, box] of Object.entries(this.selected)) {
          ids.push(box.customer_id);
        }
        this.$emit("setInvoiceCustomer", {
          customer_ids: ids,
          invoice_id: this.item.id,
        });
        this.selected = []
      }
    },
    rollbackExtraInvoice() {
      this.$emit("rollbackExtraInvoice", {
        invoice_id: this.item.id,
      });
    },
    arraySum(items, prop){
      return items.reduce( function(a,b){
        return a + b[prop]
      }, 0);
    },
    get_customer_name(item) {
      return this.getHanText(item.customer, "name");
    },
  },
  watch: {
    show(val) {
      if (val == false) {
        this.selected = []
      }
    }
  },
  mounted(){
    this.footerProps.itemsPerPageOptions = [20, 50, 100, 200]
    this.options.itemsPerPage=100
  },
};
</script>
