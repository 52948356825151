<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <CommonAutocomplete
                v-model="customer"
                :label="$t('customer.customer')"
                :placeholder="''"
                url="/customer/customer/"
                :item_text="nameField"
                item_value="id"
                :query_field="nameFieldFilter"
                :extra_filter="item"
                :filter_fields="{}"
                :cache="[customer_cache]"
                :extra_condition="{
                    enable: true
                }"
                :required="true"
              >
              </CommonAutocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
export default {
    data: (vm)=>({
        topic: vm.$i18n.t("customer.customer"),
        customer: null,
        customer_cache: null,
    }),
    computed: {
        allowSave(){
            return !this.customer
        },
    },
    components: {
        CommonAutocomplete,
    },
    mixins: [BaseDialog],
    methods: {
        save(){
            this.$emit("onSave", this.item.id, this.customer);
        },
    },
    watch: {
        item(val, oldVal) {
            if ("customer" in val && val.customer) {
                this.customer = val.customer.id;
                this.customer_cache = val.customer;
            }
        },
    },
}
</script>